export class Column {
    title: string;
    dataProperty: any;
    sortable: boolean;
    filterable: boolean;
    render?: (data: any) => string;
    filter?: FilterType.SEARCH | FilterType.DROPDOWN | FilterType.DATEPICKER;
    filterOptions?: any[];  // If filter type is dropdown, column must have filterOptions field
    btns?: any[];
    actions?: Array<{title: string; method: string; show: (data: any) => boolean}>;
    customActions?: (data: any) => any;
    columnClass?: string;
    method?: string;
    tooltip?: boolean;
}

export enum FilterType {
    SEARCH = "search",
    DROPDOWN = "dropdown",
    DATEPICKER = "datepicker"
}
