import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { map } from "rxjs/operators";

import { PennyPathGameData } from "@models/game-data/PennyPathGameData";
import { ResponseDTO } from "@models/ResponseDTO";
import { Ad, AdDTO } from "@models/Ad";

import { environment } from "../../../../environments/environment";

type GameDataResponseDTO = ResponseDTO<PennyPathGameData>;
type GameDataRequestDTO = PennyPathGameData & { poolIds?: number[] };
type AdsResponseDTO = ResponseDTO<AdDTO[]>;

const mapAdImage = (src: string, campaignId: string) => {
    if (!src.includes(environment.campaignDataStorage)) {
        const regex = /assets\/.*/;
        const match = regex.exec(src);
        const strippedSrc = match ? match[0] : src;

        return `${environment.campaignDataStorage}/${campaignId}/${strippedSrc.replace("./", "")}`;
    }
    return src;
};

@Injectable({ providedIn: "root" })
export class PennyPathService {
    private readonly API_BASE_URL = `${environment.pennyPathApiBaseUrl}/game-settings`;

    constructor(private http: HttpClient) {}

    getGameData(campaignId: string) {
        return this.http.get<GameDataResponseDTO>(`${this.API_BASE_URL}/${campaignId}`).pipe(
            map(({ data }) => data)
        );
    }

    createGameData(gameData: PennyPathGameData) {
        return this.http.post<GameDataResponseDTO>(this.API_BASE_URL, { ...gameData, poolIds: [] }).pipe(
            map(({ data }) => data)
        );
    }

    updateGameData(gameData: GameDataRequestDTO) {
        const { campaignId, ...data } = gameData;

        if (!data.multiRegistration && data.playsPerDay > 1) {
            data.playsPerDay = 1;
        }

        return this.http.put<GameDataResponseDTO>(`${this.API_BASE_URL}/${campaignId}`, data).pipe(
            map(({ data }) => data)
        );
    }

    getAds(campaignId: string) {
        return this.http.get<AdsResponseDTO>(`${environment.pennyPathApiBaseUrl}/campaigns/${campaignId}/ads`).pipe(
            map(({ data }) => data.map((ad) => ({
                ...ad,
                image: mapAdImage(ad.image, campaignId),
            })))
        );
    }

    saveAds(campaignId: string, ads: Ad[]) {
        return this.http.put<AdsResponseDTO>(`${environment.pennyPathApiBaseUrl}/campaigns/${campaignId}/ads`, ads)
            .pipe(
                map(({ data }) => data)
            );
    }
}
