<div class="br-table-wrapper">
    <app-loader [isLoading]="isLoading" [loaderHeight]="loaderHeight">
        <table class="br-table table {{ tableClass ? tableClass : '' }}" *ngIf="data">
            <thead>
                <tr>
                    <th *ngFor="let column of config.columns; let i = index;" [attr.data-testid]="'header-' + column.dataProperty">
                        <span>{{ column.title }}</span>
                        <div *ngIf="column.sortable" class="sort {{ sorts[column.dataProperty] }}"
                             (click)="sort(column.dataProperty)">
                            <svg-icon src="assets/shared/svg/sort.svg"></svg-icon>
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngIf="config.isFilterable">
                    <td *ngFor="let column of config.columns; let i = index;">
                        <div class="br-table-filter {{ filterClass ? filterClass : ''}}" *ngIf="column.filterable">
                            <app-search-input *ngIf="column.filter === 'search'" (getSearchTerm)="search($event, column.dataProperty)"></app-search-input>
                            <app-select-input
                                *ngIf="column.filter === 'dropdown'"
                                [mini]="true"
                                [(ngModel)]="filters[column.dataProperty]"
                                (ngModelChange)="filter($event, column.dataProperty)"
                                [options]="column.filterOptions"
                                [showField]="'text'"
                                [valueField]="'id'"
                                [customClass]="'br-dropdown table-dropdown'"
                            ></app-select-input>
                            <app-datepicker-input
                                *ngIf="column.filter === 'datepicker'"
                                [placeholder]="column.title"
                                [(ngModel)]="dates[column.dataProperty]"
                                (ngModelChange)="changeDate($event, column.dataProperty)"
                                [name]="column.dataProperty"
                            >
                            </app-datepicker-input>
                        </div>
                    </td>
                </tr>
                <tr *ngIf="data.length === 0">
                    <td [colSpan]="config.columns.length">
                        <span class="br-table__no-data">No data</span>
                    </td>
                </tr>
                <tr *ngFor="let columnData of data" 
                    [style.backgroundColor]="columnData.isTailorMade ? 'none' : 'inherit'">
                    <td *ngFor="let column of config.columns" [ngClass]="column.columnClass" [attr.data-testid]="'column-' + column.title.toLowerCase()">
                        <div *ngIf="column.render"
                             [innerHtml]="getRender(column, columnData)"
                             class="br-table-render"
                             (click)="column.method ? resolveAction(column.method, columnData) : resolveAction('', '')"
                             [ngbTooltip]="column.tooltip ? tooltipTemplate : null"
                             placement="bottom"
                        >
                            <ng-template #tooltipTemplate>
                                <span *ngIf="column.title === 'Parents'">
                                    <div *ngFor="let company of columnData.parentCompanies">
                                        {{ company.name }} ({{company.id.split('-')[0]}})
                                    </div>
                                    <span *ngIf="!columnData.parentCompanies?.length">No data.</span>
                                </span>
                                <span *ngIf="column.title === 'Children'">
                                    <div *ngFor="let company of columnData.childCompanies">
                                        {{ company.name }} ({{company.id.split('-')[0]}})
                                    </div>
                                    <span *ngIf="!columnData.childCompanies?.length">No data.</span>
                                </span>
                            </ng-template>
                        </div>
                        <span class="br-table-value" *ngIf="!column.render && column.title">
                            {{ columnData[column.dataProperty] }}
                        </span>
                        <div class="br-table-btns" *ngIf="column.btns">
                            <div class="stopwatch"
                                *ngIf="columnData.status === 'SCHEDULED'"
                                 [ngbTooltip]="tooltipTemplate"
                                 placement="bottom"
                                 tooltipClass="green-tooltip">
                                <ng-template #tooltipTemplate>
                                    <div>Campaign starts on {{ columnData.startDate | date: 'dd.MM.yyyy' }}</div>
                                    <div>Campaign ends on {{ columnData.endDate | date: 'dd.MM.yyyy' }}</div>
                                </ng-template>
                                <svg-icon src="assets/shared/svg/stopwatch.svg"></svg-icon>
                            </div>
                            <button class="btn btn-link" *ngIf="!columnData.isTailorMade && columnData.status != 'DELETED' && showButton(column.btns, 'edit') && editCampaignPermission()" (click)="resolveLinkAction('edit', columnData[getLinkActionParam(column.btns, 'edit')])"
                                    type="button" [attr.data-testid]="'edit-button-' + columnData.id">
                                    <svg-icon src="assets/shared/svg/edit-pencil.svg"></svg-icon>
                                {{ 'analytics.edit' | translate }}
                            </button>
                            <button class="btn btn-link" *ngIf="showButton(column.btns, 'analytics')" type="button" (click)="resolveLinkAction('analytics', columnData[getLinkActionParam(column.btns, 'analytics')])" [attr.data-testid]="'analytics-button-' + columnData.id">
                                    <svg-icon src="assets/shared/svg/analytics-small.svg"></svg-icon>
                                {{ 'analytics.analytics' | translate }}
                            </button>
                            <button class="btn btn-link" *ngIf="showButton(column.btns, 'EDIT_EMPLOYEE') && columnData.status !== 'DELETED'" (click)="resolveAction('EDIT_EMPLOYEE', columnData)"
                                    type="button" [attr.data-testid]="'edit-button-' + columnData.id">
                                    <svg-icon src="assets/shared/svg/edit-pencil.svg"></svg-icon>
                                {{ 'userManagement.edit' | translate }}
                            </button>
                        </div>

                        <div class="dropdown br-table-actions" ngbDropdown *ngIf="getCustomActions(column, columnData).length > 0">
                            <div *ngIf="columnData.languages.length > 1">
                                <button class="btn btn-link dropdown-toggle" type="button" id="actionDropdown" ngbDropdownToggle [attr.data-testid]="'link-button-' + columnData.id">
                                    <svg-icon src="assets/shared/svg/link.svg"></svg-icon>
                                    Link
                                    <svg-icon src="assets/shared/svg/down.svg" class="down-icon"></svg-icon>
                                </button>
                                <div class="dropdown-menu" aria-labelledby="actionDropdown" ngbDropdownMenu>
                                    <ng-container *ngFor="let action of getCustomActions(column, columnData)">
                                        <button class="dropdown-item" ngbDropdownItem (click)="resolveLinkAction('live', columnData.productionUrl, action.shortName)" [attr.data-testid]="'edit-button-' + columnData.id">
                                            <img [src]="action.icon" class="flag-icon" alt="Flag" >
                                            {{ action.name }}
                                        </button>
                                    </ng-container>
                                </div>
                            </div>
                            <div *ngIf="columnData.languages.length === 1">
                                <button class="btn btn-link" type="button" (click)="resolveLinkAction('live', columnData.productionUrl)" [attr.data-testid]="'link-button-' + columnData.id">
                                    <svg-icon src="assets/shared/svg/link.svg"></svg-icon>
                                    Link
                                </button>
                            </div>
                        </div>
                        <div class="dropdown br-table-actions" ngbDropdown
                            *ngIf="column.actions && ifVisibleOptions(column.actions, columnData) && editCampaignPermission() && ((editCampaignPermission() && !column.isUserManagementColumn) || (!column.isUserManagementColumn || columnData.status !== 'DELETED'))"
                        >
                            <button class="btn btn-link dropdown-toggle" type="button" id="actionDropdown1" ngbDropdownToggle [attr.data-testid]="'more-button-' + columnData.id">
                                <svg-icon src="assets/shared/svg/icn-more-alt.svg"></svg-icon>
                                More
                            </button>
                            <div class="dropdown-menu" aria-labelledby="actionDropdown" ngbDropdownMenu>
                                <ng-container *ngFor="let action of column.actions">
                                    <button class="dropdown-item" ngbDropdownItem *ngIf="action.show(columnData)" (click)="resolveAction(action.method, columnData)" [attr.data-testid]="'action-button-' + action.title.toLowerCase()">
                                        {{ action.title }}
                                    </button>
                                </ng-container>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="br-table-wrapper__footer" *ngIf="data && data.length > 0">
            <div class="pagination-data">
                <span>Showing {{ data.length }}/{{ total }} entries</span>
            </div>
            <div class="pagination-container">
                <ngb-pagination
                    class="br-table-pagination"
                    [(page)]="currentPage"
                    [pageSize]="config.pageSize"
                    [collectionSize]="total"
                    [maxSize]="visiblePages"
                    [boundaryLinks]="true"
                    (pageChange)="changePage($event)"
                >
                </ngb-pagination>
            </div>
        </div>
    </app-loader>
</div>
