import { NgModule } from "@angular/core";
import { TextOverflowDirective } from "./text-overflow.directive";
import { FeatureFlagsDirective } from "./feature-flags.directive";
import { HasRoleDirective } from "./has-role.directive";

@NgModule({
    declarations: [TextOverflowDirective, FeatureFlagsDirective, HasRoleDirective],
    exports: [TextOverflowDirective, FeatureFlagsDirective, HasRoleDirective],
})
export class DirectivesModule {}
